import { NumberOrString } from "@/core/directive/type/common";
import {
  ProductItemImage,
  ProductItemOfficialPrices,
  ProductItemShow,
} from "@/core/directive/interface/order";

export interface PayOrder {
  pay_order_no: string;
  apply_refund_amount: string;
}

export interface orderRefundData {
  payment_document: string;
  refund_reason: string;
  refund_reason_note: string;
  selected_products: string[];
}
export interface orderRefundDataPaymentDocumentListItem {
  is_selected: boolean;
  pay_order_no: string;
  apply_refund_amount: string;
}

export const orderRefundData: orderRefundData = {
  payment_document: "",
  refund_reason: "",
  refund_reason_note: "",
  selected_products: [],
};

export interface ProductItem {
  guid: string;
  status: number;
  stage: {
    node_code: number;
    node: string;
    key: string;
  };
  image: string;
  name: string;
  name_en: string;
  size: string;
  color: string;
  original_color: string;
  brand_article_no: string;
  merchant_article_no: string;
  brand: string;
  unit_price: string;
  total: string;
}

export const ProductItem: ProductItem = {
  guid: "",
  status: 0,
  stage: {
    node_code: 0,
    node: "",
    key: "",
  },
  image: "",
  name: "",
  name_en: "",
  size: "",
  color: "",
  original_color: "",
  brand_article_no: "",
  merchant_article_no: "",
  brand: "",
  unit_price: "",
  total: "",
};
export interface paymentDocumentListItem {
  yaband_order_id: string;
  pay_order_no: string;
  currency: string;
  amount: string;
  refundable_amount: string;
  pay_date: string;
}

export const paymentDocumentListItem: paymentDocumentListItem = {
  yaband_order_id: "",
  pay_order_no: "",
  currency: "EUR",
  amount: "0",
  refundable_amount: "",
  pay_date: "0000-00-00 00:00:00",
};