
import { computed, defineComponent, onMounted, ref } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { showModal, modalShowListener } from "@/core/directive/function/common";
import { useI18n } from "vue-i18n";
import { CommonHtmlType } from "@/core/directive/type/common";
import {
  ProductItem,
  paymentDocumentListItem,
  PayOrder,
  orderRefundDataPaymentDocumentListItem,
} from "@/core/directive/interface/orderRefund";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import { ApiOrderRefund } from "@/core/api";
import { useRoute } from "vue-router";
import mixin from "@/mixins";
import { Decimal } from "decimal.js";

export default defineComponent({
  name: "mb-order-routing-refund-overview-batch-refund-modal",
  components: { ProductInfo },
  emits: ["batch-refund-submit"],
  props: {
    product: {
      type: Object as () => ProductItem,
      required: true,
      default: () => ({
        guid: "",
        name: "",
        name_en: "",
        brand: "",
        image: "",
        unit_price: 0,
        total: 0,
        stage: { node: "" },
      }),
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const loading = ref<boolean>(true);
    const orderRoutingBatchRefundModalRef = ref<CommonHtmlType>(null);
    const formData = ref<Array<orderRefundDataPaymentDocumentListItem>>([]);
    const { showServerErrorMsg } = mixin();

    const getColumnWidth = (Column: number) => {
      switch (Column) {
        case 0:
          return "3%";
        case 1:
          return "50%";
        default:
          return "30%";
      }
    };

    const paymentDocumentList = [] as paymentDocumentListItem[];

    const totalRefundComputed = computed(() => {
      // 计算总退款金额，使用Decimal避免精度问题
      let totalRefund = new Decimal(0);
      for (let i = 0; i < formData.value.length; i++) {
        if (formData.value[i].is_selected) {
          totalRefund = totalRefund.plus(
            new Decimal(formData.value[i].apply_refund_amount) || 0
          );
        }
      }
      return totalRefund.toNumber();
    });

    const getRefundablePaymentDocumentList = async () => {
      loading.value = true;
      const { data } = await ApiOrderRefund.getRefundablePaymentDocumentList({
        item_id: route.params.id,
      });
      if (data.code === 0) {
        paymentDocumentList.splice(
          0,
          paymentDocumentList.length,
          ...data.data.yaband_pay_orders
        );
        for (let i = 0; i < paymentDocumentList.length; i++) {
          formData.value.splice(i, 1, {
            is_selected: false,
            pay_order_no: paymentDocumentList[i].pay_order_no,
            apply_refund_amount: "0",
          });
        }
      } else {
        showServerErrorMsg(data);
      }
      loading.value = false;
    };

    const onSubmitButtonClick = () => {
      if (totalRefundComputed.value == Number(props.product.total)) {
        // 退款金额与产品金额一致，直接提交
        const tempData = [] as PayOrder[];
        for (let i = 0; i < formData.value.length; i++) {
          if (formData.value[i].is_selected) {
            tempData.push({
              pay_order_no: formData.value[i].pay_order_no,
              apply_refund_amount: formData.value[i].apply_refund_amount,
            });
          }
        }
        emit("batch-refund-submit", tempData);
        hideModal(orderRoutingBatchRefundModalRef.value);
      }
    };

    const handleModalHidden = () => {
      paymentDocumentList.splice(0, paymentDocumentList.length);
      formData.value.splice(0, formData.value.length);
    };

    const modalShow = () => {
      showModal(orderRoutingBatchRefundModalRef.value);
      getRefundablePaymentDocumentList();
    };

    const modalHide = () => {
      hideModal(orderRoutingBatchRefundModalRef.value);
    };

    onMounted(() => {
      modalShowListener(orderRoutingBatchRefundModalRef.value, () => {
        //
      });
      modalHideListener(
        orderRoutingBatchRefundModalRef.value,
        handleModalHidden
      );
    });

    return {
      t,
      props,
      orderRoutingBatchRefundModalRef,
      loading,
      getColumnWidth,
      formData,
      totalRefundComputed,
      onSubmitButtonClick,
      modalShow,
      modalHide,
      paymentDocumentList,
    };
  },
});
