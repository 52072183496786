
import { defineComponent, onMounted, useAttrs, ref, computed } from "vue";
import {
  commonBackToList,
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";
import {
  orderRefundData,
  ProductItem,
  paymentDocumentListItem,
  PayOrder,
} from "@/core/directive/interface/orderRefund";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import JwtService from "@/core/services/JwtService";
import { ApiOrderRefund } from "@/core/api";
import mixin from "@/mixins";
import Swal from "sweetalert2/dist/sweetalert2.js";
import BatchRefundModal from "./BatchRefundModal.vue";
import { Decimal } from "decimal.js";

interface RefundTypeParamsConfig {
  refundType: string;
  submitTips: string;
  showInfoApi: any;
  submitApi: any;
}
export default defineComponent({
  name: "mb-order-routing-routing-overview-refund",
  methods: { formatDate, formatDateTime },
  components: { SkuInfo, ProductInfo, BatchRefundModal },
  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const attrs = useAttrs(); // 接收父路由参数
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const formRef = ref<null | HTMLFormElement>(null);
    const applyButton = ref<HTMLElement | null>(null);
    const loading = ref(false);
    const search = ref<string>("");
    const formData = ref(Object.assign({}, _.cloneDeep(orderRefundData)));
    const orderInfo = ref({
      order_number: "",
      position: 0,
      total_item_count: 0,
    });
    const productList = ref<ProductItem[]>([]);
    const productListBackup = ref<ProductItem[]>([]);
    const paymentDocumentList = ref<paymentDocumentListItem[]>([]);
    const selectAll = ref(false);
    const orderRoutingBatchRefundModalRef = ref();
    const batchRefundProduct = ref<ProductItem>();

    const getRefundTypeParams = computed(() => {
      let params: RefundTypeParamsConfig = {
        refundType: "",
        submitTips: "",
        showInfoApi: undefined,
        submitApi: undefined,
      };
      switch (route.name) {
        case "mb-order-routing-routing-overview-refund":
          params = {
            refundType: "invoice_refund",
            submitTips: t("orderRefund.submitTip.confirmRefundOperation"),
            showInfoApi: ApiOrderRefund.getOrderRefundIndex,
            submitApi: ApiOrderRefund.submitOrderRefund,
          };
          break;
        case "mb-order-routing-routing-overview-return-refund":
          params = {
            refundType: "return_refund",
            submitTips: t("orderRefund.submitTip.confirmReturnRefundOperation"),
            showInfoApi: ApiOrderRefund.getOrderReturnRefundIndex,
            submitApi: ApiOrderRefund.submitOrderReturnRefund,
          };
          break;
        default:
          // eslint-disable-next-line no-case-declarations
          const n: any = route.name;
          console.log(n);
          break;
      }
      return params;
    });

    const rules = ref({
      payment_document: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      refund_reason: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      refund_reason_note: [
        {
          required: false,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (
                (value === "" || value === null) &&
                formData.value.refund_reason === "other"
              ) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      selected_products: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const getShowInfo = async () => {
      const { data } = await getRefundTypeParams.value.showInfoApi({
        item_id: route.params.id,
      });
      if (data.code == 0) {
        productList.value.splice(
          0,
          productList.value.length,
          ...data.data.items
        );
        productListBackup.value.splice(
          0,
          productListBackup.value.length,
          ...data.data.items
        );
        paymentDocumentList.value.splice(
          0,
          paymentDocumentList.value.length,
          ...data.data.yaband_pay_orders
        );
        orderInfo.value = data.data.order_info;
      } else {
        showServerErrorMsg(data);
      }
    };

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([getShowInfo()])
        .then(() => {
          loading.value = false;
          checkedDefaultPaymentDocument();
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const justOnePaymentDocument = computed(() => {
      return paymentDocumentList.value.length === 1;
    });

    const checkedDefaultPaymentDocument = () => {
      if (justOnePaymentDocument.value) {
        formData.value.payment_document =
          paymentDocumentList.value[0].pay_order_no;
      } else {
        formData.value.payment_document = "";
      }
    };

    const isZeroAmountPaymentDocument = (item: paymentDocumentListItem) => {
      return Number(item.amount) === 0;
    };

    const refund_reason_list = [
      {
        value: "unable_to_purchase",
        label: t("orderRefund.refundReasonLabel.unableToPurchase"),
      },
      {
        value: "merchant_does_not_deliver_on_time",
        label: t("orderRefund.refundReasonLabel.merchantDoesNotDeliverOnTime"),
      },
      {
        value: "merchant_misses_shipment",
        label: t("orderRefund.refundReasonLabel.merchantMissesShipment"),
      },
      {
        value: "price_increase",
        label: t("orderRefund.refundReasonLabel.priceIncrease"),
      },
      {
        value: "defective_goods_wrong_goods",
        label: t("orderRefund.refundReasonLabel.defectiveGoodsWrongGoods"),
      },
      {
        value: "other",
        label: t("orderRefund.refundReasonLabel.other"),
      },
    ] as RefundReason[];

    type RefundReason = {
      value: string;
      label: string;
    };

    const groupedRefundReasonList = computed(() => {
      const groups: RefundReason[][] = [];
      for (let i = 0; i < refund_reason_list.length; i += 3) {
        groups.push(refund_reason_list.slice(i, i + 3));
      }
      return groups;
    });

    const getColumnWidth = (Column: number) => {
      switch (Column) {
        case 0:
          return "3%";
        case 1:
          return "17%";
        case 2:
          return "17%";
        case 3:
          return "20%";
        case 4:
          return "20%";
        case 5:
          return "15%";
        case 6:
          return "15%";
        default:
          return "10%";
      }
    };

    const getName = (item: any) => {
      return JwtService.getLang() === "zh_CN" ? item.name : item.name_en;
    };

    const thisItemIsSelected = (item: any) => {
      return formData.value.selected_products.includes(item.guid);
    };

    const handleSelectAll = () => {
      if (!selectAll.value) {
        if (
          formData.value.selected_products.length !== productList.value.length
        ) {
          formData.value.selected_products = productList.value.map(
            (item) => item.guid
          );
          selectAll.value = true;
        }
      } else {
        if (
          formData.value.selected_products.length === productList.value.length
        ) {
          formData.value.selected_products = [];
          selectAll.value = false;
        }
      }
    };

    const resetSelectAll = () => {
      if (
        formData.value.selected_products.length === productList.value.length
      ) {
        selectAll.value = true;
      } else {
        selectAll.value = false;
      }
    };

    const totalRefundComputed = computed(() => {
      // 计算总退款金额，使用Decimal避免精度问题
      let totalRefund = new Decimal(0);
      for (let i = 0; i < productListBackup.value.length; i++) {
        if (thisItemIsSelected(productListBackup.value[i])) {
          totalRefund = totalRefund.plus(
            new Decimal(productListBackup.value[i].total || 0)
          );
        }
      }
      return totalRefund.toNumber();
    });

    const filterList = () => {
      const query = search.value.trim();
      productList.value.splice(
        0,
        productList.value.length,
        ...productListBackup.value
      );
      if (query) {
        const result = productList.value.filter((item) => {
          const fields = [
            "guid",
            "brand",
            "brand_article_no",
            "unit_price",
            "total",
          ];
          return fields.some((field) =>
            item[field].toString().toLowerCase().includes(query.toLowerCase())
          );
        });
        productList.value.splice(0, productList.value.length, ...result);
      }
      resetSelectAll();
    };

    /**
     * @description: 搜索时
     */
    const debounceSearch = _.debounce(filterList, 20);
    const searchItems = () => {
      debounceSearch();
    };
    const removeSearch = () => {
      search.value = "";
      filterList();
    };

    const formSubmit = async () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid) => {
        if (valid) {
          Swal.fire({
            title: getRefundTypeParams.value.submitTips,
            icon: "warning",
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: t("common.yes"),
            cancelButtonText: t("common.noCancel"),
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-light",
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              loading.value = true;
              if (applyButton.value) {
                applyButton.value.setAttribute("data-kt-indicator", "on");
              }
              const { data } = await getRefundTypeParams.value.submitApi({
                item_id: route.params.id,
                identification_code: formData.value.selected_products,
                pay_order_no: formData.value.payment_document,
                refund_reason: formData.value.refund_reason,
                note: formData.value.refund_reason_note,
              });
              if (data.code === 0) {
                // 所选择的支付单足够直接退款
                showFormSubmitSuccessMsg(() => {
                  backToList();
                });
              } else {
                if (data.sub_code.includes(".601")) {
                  // 所选择的支付单不够直接退款，但是可以分多笔退款
                  Swal.fire({
                    html: `<p>${t(
                      "orderRefund.submitTip.remainingRefundableAmount1"
                    )}<span>${data.sub_msg}</span></p>`,
                    icon: "warning",
                    buttonsStyling: false,
                    showCancelButton: true,
                    confirmButtonText: t("orderRefund.splitRefunds"),
                    cancelButtonText: t("common.cancel"),
                    customClass: {
                      confirmButton: "btn btn-primary",
                      cancelButton: "btn btn-light",
                    },
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      // 打开分多笔退款弹窗
                      openBatchRefund();
                    }
                  });
                } else if (data.sub_code.includes(".602")) {
                  // 所选择的支付单不够直接退款，而且不满足分多笔退款的条件
                  Swal.fire({
                    html: `<p>${t(
                      "orderRefund.submitTip.remainingRefundableAmount2"
                    )}</p>`,
                    icon: "warning",
                    buttonsStyling: false,
                    confirmButtonText: t("common.cancel"),
                    customClass: {
                      confirmButton: "btn btn-light",
                    },
                  });
                } else {
                  showServerErrorMsg(data);
                }
              }
              loading.value = false;
              applyButton.value?.removeAttribute("data-kt-indicator");
            }
          });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const openBatchRefund = () => {
      if (!orderRoutingBatchRefundModalRef.value) {
        return;
      }
      batchRefundProduct.value = productList.value.filter((item) => {
        return thisItemIsSelected(item);
      })[0];
      orderRoutingBatchRefundModalRef.value?.modalShow();
    };

    const handleBatchRefundSubmit = async (payment_documents: PayOrder[]) => {
      // 分多笔退款
      loading.value = true;
      if (applyButton.value) {
        applyButton.value.setAttribute("data-kt-indicator", "on");
      }

      const { data } = await ApiOrderRefund.submitBatchRefund({
        item_id: route.params.id,
        identification_code: formData.value.selected_products,
        pay_orders: payment_documents,
        refund_type: getRefundTypeParams.value.refundType,
        refund_reason: formData.value.refund_reason,
        note: formData.value.refund_reason_note,
      });
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          backToList();
        });
      } else {
        showServerErrorMsg(data);
      }
      loading.value = false;
      applyButton.value?.removeAttribute("data-kt-indicator");
    };

    const goPath = computed(() => {
      return "/mb-order-routing/routing-overview";
    });

    const backToList = () => {
      commonBackToList(router, goPath.value);
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      getFormInfo();
    });
    return {
      t,
      loading,
      formData,
      formRef,
      rules,
      paymentDocumentList,
      justOnePaymentDocument,
      isZeroAmountPaymentDocument,
      refund_reason_list,
      groupedRefundReasonList,
      orderInfo,
      productList,
      productListBackup,
      getColumnWidth,
      getName,
      thisItemIsSelected,
      handleSelectAll,
      resetSelectAll,
      totalRefundComputed,
      search,
      searchItems,
      removeSearch,
      formSubmit,
      applyButton,
      selectAll,
      backToList,
      orderRoutingBatchRefundModalRef,
      batchRefundProduct,
      handleBatchRefundSubmit,
    };
  },
});
